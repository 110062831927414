body, html {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100% !important;
  max-width: 100% !important;
}

.passwordFiled {
  position: relative;
}

.passwordFiled svg {
  position: absolute;
  right: 14px;
  top: 38px;
  font-size: 17px;
  color: #444;
}

.loginForm {
  width: 420px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

.registerForm {
  width: 420px;
  max-width: 100%;
  margin: 20px auto 40px;
  padding: 0 20px;
}

.regitestLink {
  margin-top: -33px;
}

.text-red {
  color: red;
  margin-bottom: 15px;
  font-size: 12px;
}

.text-green {
  color: green;
  margin-bottom: 15px;
  font-size: 12px;
}

.languageSelector {
  top: 6px;
  position: absolute;
  right: 10px;
}

.languageSelector select{
  padding: 5px;
  background: none;
  color: #fff;
  border: 0;
  outline: 0;
}

.contentPara .mr-5 {
  margin-right: 0px;
}

.languageSelector select option{
  color: #000;
}

.forgetSection {
  position: relative;
}

.linkColor {
  text-decoration: underline;
  cursor: pointer;
}

.contentPara{
  font-size: 14px;
}

.linkRight {
  text-align: right;
  margin-top: -32px;
}

.errorMsg {
  color: red;
  text-align: center;
  font-size: 15px;
}

.success-message {
  color: green;
  text-align: center;
  font-size: 15px;
  line-height: 20px;
  font-weight: bold;
  margin: 15px 0;
}

button[disabled] {
  opacity: 0.5;
}

.loader {
  color: grey;
  font-size: 14px;
  margin: 15px 0;
}

.linkRight a {
  margin: 0 8px;
  font-size: 14px;
  text-decoration: underline;
}

.forgetLink {
  position: absolute;
  top: 38px;
  right: 16px;
  font-size: 13px;
  text-decoration: underline;
}

.header {
  margin: 0;
}

.header a {
 display: inline-block;
}

.content {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 70px;
}

.footer {
  margin-top: auto;
}

.logo {
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 30px;
  padding-top: 20px;
}

.logo img {
  margin: 0 auto;
  max-width: 120px;
}

.headerTop {
  background: #000;
  text-align: center;
  color: #fff;
  font-size: 14px;
  padding: 10px;
}

.footerBottom {
  background: #000;
  text-align: center;
  color: #fff;
  font-size: 14px;
  padding: 10px;
  width: 100%;
}

.loading {
  position: fixed;
  margin: auto;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.9);
}

.loadingWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#loading {
  margin: auto;
  height: 70px;
  width: 70px;
  border: transparent;
  border-top: 2px solid #fff;
  border-radius: 50%;
  animation: round 2s linear infinite;
}

.loadMessage {
  color: #ffffff;
  position: relative;
  top: 50%;
  font-weight: normal;
  margin: 20px auto;
  display: inline-block;
}

@keyframes round {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}